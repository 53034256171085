import logo from './logo.svg'
import './App.css'

let started = process.env.REACT_APP_STARTED
let today = new Date().toDateString()
console.log(`started - ${today} - today's hours - ${Date.parse(today)}`)
function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <h1 className='text-7xl font-black !text-pink-600'>
          ❤️GOOSE LOVES REACT❤️
        </h1>
        <a
          className='App-link text-2xl'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React TODAY!!!
        </a>
      </header>
    </div>
  )
}

export default App
